import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Fade from "@mui/material/Fade";

const ScreenOne = (props) => {

  const [select, setSelect] = useState('');

  const changeHandler = (event) => {
    props.selected(event.target.value);
    if(event.target.value === 'One') {
      setSelect('One');
    } else if(event.target.value === 'Two') {
      setSelect('Two');
    } else {
      setSelect('btn');
    }
  };

  return (
    <Fade in={true} timeout={2000}>
      <div>
        <ToggleButtonGroup
          orientation="vertical"
          exclusive
          className={select === 'One' ? 'activeOneBtn questionContainer' : select === 'Two' ? 'activeTwoBtn questionContainer' : 'btn questionContainer'}
          onChange={changeHandler}
        >
          <h3>{props.screenTitle}</h3>
          {props.surveyOptions.map((item, index) => (
            <ToggleButton
              value={item.option}
              aria-label={item.option}
              key={item.option}
            >
              {item.option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </Fade>
  );
};

export default ScreenOne;
