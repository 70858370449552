import { useFormik } from "formik";
import ScreenOne from "../../parts/surveyScreens/screen-one/screen-one";
import data from "../../data/data.json";
import * as Yup from "yup";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CurrencyInput from "react-currency-input-field";

const LatestForm = (props) => {
  const [step, setStep] = React.useState(0);
  const [applicant, setApplicant] = React.useState("One");
  const [active, setActive] = React.useState(false);
  const [annualIncome, setAnnualIncome] = React.useState("");
  const [grossIncome, setGrossIncome] = React.useState("");
  // const [totalIncome, setTotalIncome] = React.useState("");
  const [previousAnnualIncome, setPreviousAnnualIncome] = React.useState("");
  const [secondAnnualIncome, setSecondAnnualIncome] = React.useState("");
  const [secondGrossIncome, setSecondGrossIncome] = React.useState("");
  // const [secondTotalIncome, setSecondTotalIncome] = React.useState("");
  const [secondPreviousAnnualIncome, setSecondPreviousAnnualIncome] =
    React.useState("");
  const [cashInBank, setCashInBank] = React.useState("");
  const [stocksBondsInvestment, setStocksBondsInvestment] = React.useState("");
  const [householdEffects, setHouseholdEffects] = React.useState("");
  const [automobilesTrailers, setAutomobilesTrailers] = React.useState("");
  const [other, setOther] = React.useState("");
  const [totalAssets, setTotalAssets] = React.useState("");
  const [totalNetWorth, setTotalNetWorth] = React.useState("");
  const [mortgageBalance, setMortgageBalance] = React.useState("");
  const [mortgagePayment, setMortgagePayment] = React.useState("");
  const [otherMortgageBalance, setOtherMortgageBalance] = React.useState("");
  const [otherMortgagePayment, setOtherMortgagePayment] = React.useState("");
  const [personalLoanBalance, setPersonalLoanBalance] = React.useState("");
  const [personalLoanPayment, setPersonalLoanPayment] = React.useState("");
  const [creditCardsBalance, setCreditCardsBalance] = React.useState("");
  const [creditCardsPayment, setCreditCardsPayment] = React.useState("");
  const [studentLoanBalance, setStudentLoanBalance] = React.useState("");
  const [studentLoanPayment, setStudentLoanPayment] = React.useState("");
  const [autoLoanBalance, setAutoLoanBalance] = React.useState("");
  const [autoLoanPayment, setAutoLoanPayment] = React.useState("");
  const [otherLoanBalance, setOtherLoanBalance] = React.useState("");
  const [otherLoanPayment, setOtherLoanPayment] = React.useState("");
  const [totalLiabilitiesBalance, setTotalLiabilitiesBalance] =
    React.useState("");
  const [totalLiabilitiesPayment, setTotalLiabilitiesPayment] =
    React.useState("");
  const [subjectPropertyAppraisedValue, setSubjectPropertyAppraisedValue] =
    React.useState("");
  const [
    subjectPropertyAnnualPropertyTax,
    setSubjectPropertyAnnualPropertyTax,
  ] = React.useState("");
  const [subjectPropertyMonthlyMaintFee, setSubjectPropertyMonthlyMaintFee] =
    React.useState("");
  const [subjectPropertyRentalIncome, setSubjectPropertyRentalIncome] =
    React.useState("");
  const [mortgageBalanceFirstMortgage, setMortgageBalanceFirstMortgage] =
    React.useState("");
  const [mortgageBalanceSecondMortgage, setMortgageBalanceSecondMortgage] =
    React.useState("");
  const [mortgageBalanceThirdMortgage, setMortgageBalanceThirdMortgage] =
    React.useState("");
  const [monthlyPaymentsFirstMortgage, setMonthlyPaymentsFirstMortgage] =
    React.useState("");
  const [monthlyPaymentsSecondMortgage, setMonthlyPaymentsSecondMortgage] =
    React.useState("");
  const [monthlyPaymentsThirdMortgage, setMonthlyPaymentsThirdMortgage] =
    React.useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      sin: "",
      dob: "",
      currentAddress: "",
      durationAddress: "",
      previousAddress: "",
      maritalStatus: "",
      dependents: "",
      homePhone: "",
      officePhone: "",
      cellPhone: "",
      emailOne: "",
      emailTwo: "",
      currentEmployer: "",
      durationEmployed: "",
      Address: "",
      phone: "",
      employmentEmail: "",
      position: "",
      incomeType: "",
      annualIncome: "",
      additionalJob: "",
      addtionalIncomeType: "",
      otherIncomeTypeOne: "",
      otherDescriptionOne: "",
      otherAnnualIncomeOne: "",
      otherIncomeTypeTwo: "",
      otherDescriptionTwo: "",
      otherAnnualIncomeTwo: "",
      grossIncome: "",
      // totalIncome: "",
      previousEmployer: "",
      previousDurationEmployed: "",
      previousPhone: "",
      previousEmail: "",
      previousPosition: "",
      previousIncomeType: "",
      previousAnnualIncome: "",
      secondName: "",
      secondSin: "",
      secondDob: "",
      secondCurrentAddress: "",
      secondDurationAddress: "",
      secondPreviousAddress: "",
      secondMaritalStatus: "",
      secondDependents: "",
      secondHomePhone: "",
      secondOfficePhone: "",
      secondCellPhone: "",
      secondEmailOne: "",
      secondEmailTwo: "",
      secondCurrentEmployer: "",
      secondDurationEmployed: "",
      secondAddress: "",
      secondPhone: "",
      secondEmploymentEmail: "",
      secondPosition: "",
      secondIncomeType: "",
      secondAnnualIncome: "",
      secondAdditionalJob: "",
      secondAddtionalIncomeType: "",
      secondOtherIncomeTypeOne: "",
      secondOtherDescriptionOne: "",
      secondOtherAnnualIncomeOne: "",
      secondOtherIncomeTypeTwo: "",
      secondOtherDescriptionTwo: "",
      secondOtherAnnualIncomeTwo: "",
      secondGrossIncome: "",
      // secondTotalIncome: "",
      secondPreviousEmployer: "",
      secondPreviousDurationEmployed: "",
      secondPreviousPhone: "",
      secondPreviousEmail: "",
      secondPreviousPosition: "",
      secondPreviousIncomeType: "",
      secondPreviousAnnualIncome: "",
      subjectProperty: "",
      totalOtherRealEstate: "",
      cashInBank: "",
      business: "",
      stocksBondsInvestment: "",
      householdEffects: "",
      automobilesTrailers: "",
      other: "",
      totalAssets: "",
      totalNetWorth: "",
      mortgageBalance: "",
      mortgagePayment: "",
      otherMortgageBalance: "",
      otherMortgagePayment: "",
      newMortgageBalance: "",
      newMortgagePayment: "",
      personalLoanBalance: "",
      personalLoanPayment: "",
      creditCardsBalance: "",
      creditCardsPayment: "",
      studentLoanBalance: "",
      studentLoanPayment: "",
      autoLoanBalance: "",
      autoLoanPayment: "",
      otherLoanBalance: "",
      otherLoanPayment: "",
      totalLiabilitiesBalance: "",
      totalLiabilitiesPayment: "",
      subjectPropertyAddress: "",
      subjectPropertyLegal: "",
      subjectPropertyPosition: "",
      subjectPropertyAppraisalCompany: "",
      subjectPropertyAppraiserName: "",
      subjectPropertyAppraisalDate: "",
      subjectPropertyAppraisedValue: "",
      subjectPropertyAnnualPropertyTax: "",
      subjectPropertyMonthlyMaintFee: "",
      subjectPropertyArrears: "",
      subjectPropertyOccupancy: "",
      subjectPropertyStyle: "",
      subjectPropertyZoning: "",
      subjectPropertyNumberOfUnits: "",
      subjectPropertyRentalIncome: "",
      mortgageHolderFirstMortgage: "",
      mortgageHolderSecondMortgage: "",
      mortgageHolderThirdMortgage: "",
      mortgageNumberFirstMortgage: "",
      mortgageNumberSecondMortgage: "",
      mortgageNumberThirdMortgage: "",
      mortgageBalanceFirstMortgage: "",
      mortgageBalanceSecondMortgage: "",
      mortgageBalanceThirdMortgage: "",
      monthlyPaymentsFirstMortgage: "",
      monthlyPaymentsSecondMortgage: "",
      monthlyPaymentsThirdMortgage: "",
      interestRateFirstMortgage: "",
      interestRateSecondMortgage: "",
      interestRateThirdMortgage: "",
      maturityDateFirstMortgage: "",
      maturityDateSecondMortgage: "",
      maturityDateThirdMortgage: "",
      arrearFirstMortgage: "",
      arrearSecondMortgage: "",
      arrearThirdMortgage: "",
    },
    onSubmit: function (values) {
      if (step === 3) {
        props.values({
          ...values,
          annualIncome: annualIncome,
          grossIncome: grossIncome,
          // totalIncome: totalIncome,
          previousAnnualIncome: previousAnnualIncome,
          secondAnnualIncome: secondAnnualIncome,
          secondGrossIncome: secondGrossIncome,
          // secondTotalIncome: secondTotalIncome,
          secondPreviousAnnualIncome: secondPreviousAnnualIncome,
          cashInBank: cashInBank,
          stocksBondsInvestment: stocksBondsInvestment,
          householdEffects: householdEffects,
          automobilesTrailers: automobilesTrailers,
          other: other,
          totalAssets: totalAssets,
          totalNetWorth: totalNetWorth,
          mortgageBalance: mortgageBalance,
          mortgagePayment: mortgagePayment,
          otherMortgageBalance: otherMortgageBalance,
          otherMortgagePayment: otherMortgagePayment,
          personalLoanBalance: personalLoanBalance,
          personalLoanPayment: personalLoanPayment,
          creditCardsBalance: creditCardsBalance,
          creditCardsPayment: creditCardsPayment,
          studentLoanBalance: studentLoanBalance,
          studentLoanPayment: studentLoanPayment,
          autoLoanBalance: autoLoanBalance,
          autoLoanPayment: autoLoanPayment,
          otherLoanBalance: otherLoanBalance,
          otherLoanPayment: otherLoanPayment,
          totalLiabilitiesBalance: totalLiabilitiesBalance,
          totalLiabilitiesPayment: totalLiabilitiesPayment,
          subjectPropertyAppraisedValue: subjectPropertyAppraisedValue,
          subjectPropertyAnnualPropertyTax: subjectPropertyAnnualPropertyTax,
          subjectPropertyMonthlyMaintFee: subjectPropertyMonthlyMaintFee,
          subjectPropertyRentalIncome: subjectPropertyRentalIncome,
          mortgageBalanceFirstMortgage: mortgageBalanceFirstMortgage,
          mortgageBalanceSecondMortgage: mortgageBalanceSecondMortgage,
          mortgageBalanceThirdMortgage: mortgageBalanceThirdMortgage,
          monthlyPaymentsFirstMortgage: monthlyPaymentsFirstMortgage,
          monthlyPaymentsSecondMortgage: monthlyPaymentsSecondMortgage,
          monthlyPaymentsThirdMortgage: monthlyPaymentsThirdMortgage,
          applicant: applicant,
        });
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      dob: Yup.string().required(),
      homePhone: Yup.number().required(),
      emailOne: Yup.string().email().required(),
      phone: Yup.number().required(),
      employmentEmail: Yup.string().email().required(),
      durationAddress: Yup.string().required(),
      cellPhone: Yup.number().required(),
    }),
  });

  const nextHandler = () => {
    console.log("next page");
    setStep(step + 1);
  };

  const backHandler = () => {
    setStep(step - 1);
  };

  // const endScreenHandler = () => {
  //   setStep(4);
  // }

  // {props.endScreen && (
  //   endScreenHandler()
  // )}

  return (
    <div className="formBlock">
      <p style={{ marginBottom: "20px" }}>
        <b style={{ color: "red" }}>NOTE:</b> Please omit questions you do not
        understand or do not know.
      </p>
      {step !== 0 && (
        <p style={{ marginBottom: "20px" }}>
          <b style={{ color: "red" }}>NOTE:</b> All fields with * must be
          completed in order to be able to submit.
        </p>
      )}

      <form onSubmit={formik.handleSubmit}>
        {step === 0 ? (
          <>
            <ScreenOne
              screenTitle={data.whatAreYouLookingFor.title}
              surveyOptions={data.whatAreYouLookingFor.options}
              selected={(val) => {
                setActive(true);
                setApplicant(val);
              }}
            />
            <div className="formBtnContainer">
              <button
                type="button"
                onClick={backHandler}
                className={step === 0 ? `disableBtn` : `enable`}
              >
                Back
              </button>
              <button
                type="button"
                key="next_button"
                onClick={nextHandler}
                className={active ? "noError" : "resolveError"}
              >
                Next
              </button>
            </div>
          </>
        ) : null}
        {step === 1 && applicant === "One" ? (
          <div className="stepContainer stepTwo">
            <h4>Applicant</h4>
            <div className="threeColBlock">
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name(first, last)*"
                  style={{ width: "100%" }}
                  className={
                    formik.touched.name && formik.errors.name
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <span className="error">{formik.errors.name}</span>
                ) : null}
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="sin"
                  style={{ width: "100%" }}
                  placeholder="SIN"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sin}
                />
                {formik.touched.sin && formik.errors.sin && (
                  <span className="error">{formik.errors.sin}</span>
                )}
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  placeholder="Date of Birth(DD/MM/YYYY)*"
                  name="dob"
                  style={{ width: "100%" }}
                  className={
                    formik.touched.dob && formik.errors.dob
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dob}
                />
                {formik.touched.dob && formik.errors.dob && (
                  <span className="error">{formik.errors.dob}</span>
                )}
              </div>
            </div>
            <div className="threeColBlock">
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="currentAddress"
                  placeholder="Current Address (complete)"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentAddress}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="durationAddress"
                  placeholder="Duration at Address*"
                  className={
                    formik.touched.durationAddress &&
                    formik.errors.durationAddress
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.durationAddress}
                />
                {formik.touched.durationAddress &&
                  formik.errors.durationAddress && (
                    <span className="error">
                      {formik.errors.durationAddress}
                    </span>
                  )}
              </div>
            </div>
            <div className="threeColBlock">
              <input
                type="text"
                name="previousAddress"
                placeholder="Previous Address (if less than 3 years)"
                className="inputField"
                style={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.previousAddress}
              />
            </div>
            <div className="threeColBlock">
              <input
                type="text"
                name="maritalStatus"
                placeholder="Marital Status"
                className="inputField"
                style={{ width: "50%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.maritalStatus}
              />
              <input
                type="text"
                name="dependents"
                placeholder="Dependents"
                className="inputField"
                style={{ width: "50%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dependents}
              />
            </div>
            <div className="threeColBlock">
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="homePhone"
                  placeholder="Home Phone*"
                  className={
                    formik.touched.homePhone && formik.errors.homePhone
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.homePhone}
                />
                {formik.touched.homePhone && formik.errors.homePhone && (
                  <span className="error">{formik.errors.homePhone}</span>
                )}
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="officePhone"
                  placeholder="Office Phone"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.officePhone}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="cellPhone"
                  placeholder="Cell Phone*"
                  className={
                    formik.touched.cellPhone && formik.errors.cellPhone
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cellPhone}
                />
                {formik.touched.cellPhone && formik.errors.cellPhone ? (
                  <span className="error">{formik.errors.cellPhone}</span>
                ) : null}
              </div>
            </div>
            <div className="threeColBlock">
              <div style={{ width: "50%" }}>
                <input
                  type="email"
                  name="emailOne"
                  placeholder="Primary Email*"
                  className={
                    formik.touched.emailOne && formik.errors.emailOne
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailOne}
                />
                {formik.touched.emailOne && formik.errors.emailOne ? (
                  <span className="error">{formik.errors.emailOne}</span>
                ) : null}
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="email"
                  name="emailTwo"
                  placeholder="Secondary Email"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailTwo}
                />
              </div>
            </div>
            <h4 style={{ marginTop: "20px" }}>
              Applicant Employment Information
            </h4>
            <div className="threeColBlock">
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  name="currentEmployer"
                  style={{ width: "100%" }}
                  placeholder="Current Employer"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentEmployer}
                />
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  name="durationEmployed"
                  style={{ width: "100%" }}
                  placeholder="Duration Employed"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.durationEmployed}
                />
              </div>
            </div>
            <div className="threeColBlock">
              <input
                type="text"
                name="Address"
                placeholder="Address (complete)"
                className="inputField"
                style={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Address}
              />
            </div>
            <div className="threeColBlock">
              <div style={{ width: "50%" }}>
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone*"
                  style={{ width: "100%" }}
                  className={
                    formik.touched.phone && formik.errors.phone
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <span className="error">{formik.errors.phone}</span>
                ) : null}
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="email"
                  name="employmentEmail"
                  placeholder="Email*"
                  style={{ width: "100%" }}
                  className={
                    formik.touched.employmentEmail &&
                    formik.errors.employmentEmail
                      ? "inputField errorField"
                      : "inputField blueBorder"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employmentEmail}
                />
                {formik.touched.employmentEmail &&
                formik.errors.employmentEmail ? (
                  <span className="error">{formik.errors.employmentEmail}</span>
                ) : null}
              </div>
            </div>
            <div className="threeColBlock">
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="position"
                  placeholder="Position"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.position}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="incomeType"
                  placeholder="Income Type"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.incomeType}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                {/* <input
                  type="number"
                  name="annualIncome"
                  placeholder="Gross Annual Income"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.annualIncome}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="annualIncome"
                  id="annualIncome"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Gross Annual Income"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setAnnualIncome(currencyValue);
                  }}
                />
              </div>
            </div>

            <div className="threeColBlock">
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="additionalJob"
                  placeholder="Additional job"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.additionalJob}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="addtionalIncomeType"
                  placeholder="Income Type"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.addtionalIncomeType}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                <CurrencyInput
                  prefix="$"
                  name="grossIncome"
                  id="grossIncome"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Gross Income"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setGrossIncome(currencyValue);
                  }}
                />
              </div>
            </div>

            {/* <div className="threeColBlock">
              <div style={{ width: "50%" }}>
                <CurrencyInput
                  prefix="$"
                  name="grossIncome"
                  id="grossIncome"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Gross Income"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setGrossIncome(currencyValue);
                  }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <CurrencyInput
                  prefix="$"
                  name="totalIncome"
                  id="totalIncome"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Total Income"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setTotalIncome(currencyValue);
                  }}
                />
              </div>
            </div> */}
            <div className="threeColBlock">
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  name="previousEmployer"
                  placeholder="Previous Employer (Complete only if current is less than 3 years)"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousEmployer}
                />
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  name="previousDurationEmployed"
                  placeholder="Duration Employed"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousDurationEmployed}
                />
              </div>
            </div>
            <div className="threeColBlock">
              <div style={{ width: "50%" }}>
                <input
                  type="text"
                  name="previousPhone"
                  placeholder="Phone"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousPhone}
                />
              </div>
              <div style={{ width: "50%" }}>
                <input
                  type="email"
                  name="previousEmail"
                  placeholder="Email"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousEmail}
                />
              </div>
            </div>
            <div className="threeColBlock">
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="previousPosition"
                  placeholder="Position"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousPosition}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                <input
                  type="text"
                  name="previousIncomeType"
                  placeholder="Income Type"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousIncomeType}
                />
              </div>
              <div style={{ width: "33.33%" }}>
                {/* <input
                  type="number"
                  name="previousAnnualIncome"
                  style={{ width: "100%" }}
                  placeholder="Gross Annual Income"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousAnnualIncome}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="previousAnnualIncome"
                  id="previousAnnualIncome"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Gross Annual Income"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setPreviousAnnualIncome(currencyValue);
                  }}
                />
              </div>
            </div>
            {applicant === "One" && (
              <div className="formBtnContainer">
                <button
                  type="button"
                  onClick={backHandler}
                  className={step === 0 ? `disableBtn` : `enable`}
                >
                  Back
                </button>
                <button
                  type="button"
                  key="next_button"
                  onClick={nextHandler}
                  className={
                    Object.keys(formik.errors).length !== 0 ||
                    formik.values.name === "" ||
                    formik.values.dob === "" ||
                    formik.values.homePhone === "" ||
                    formik.values.emailOne === "" ||
                    formik.values.phone === "" ||
                    formik.values.employmentEmail === "" ||
                    formik.values.durationAddress === "" ||
                    formik.values.cellPhone === ""
                      ? "resolveError"
                      : "noError"
                  }
                >
                  Next
                </button>
              </div>
            )}
          </div>
        ) : null}
        {step === 1 && applicant === "Two" ? (
          <>
            <div className="stepContainer stepTwo">
              <h4>Applicant</h4>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name(first, last)*"
                    style={{ width: "100%" }}
                    className={
                      formik.touched.name && formik.errors.name
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <span className="error">{formik.errors.name}</span>
                  ) : null}
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="sin"
                    style={{ width: "100%" }}
                    placeholder="SIN"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sin}
                  />
                  {formik.touched.sin && formik.errors.sin && (
                    <span className="error">{formik.errors.sin}</span>
                  )}
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    placeholder="Date of Birth(DD/MM/YYYY)*"
                    name="dob"
                    style={{ width: "100%" }}
                    className={
                      formik.touched.dob && formik.errors.dob
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dob}
                  />
                  {formik.touched.dob && formik.errors.dob && (
                    <span className="error">{formik.errors.dob}</span>
                  )}
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    name="currentAddress"
                    placeholder="Current Address (complete)"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.currentAddress}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    name="durationAddress"
                    placeholder="Duration at Address*"
                    className={
                      formik.touched.durationAddress &&
                      formik.errors.durationAddress
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.durationAddress}
                  />
                  {formik.touched.durationAddress &&
                    formik.errors.durationAddress && (
                      <span className="error">
                        {formik.errors.durationAddress}
                      </span>
                    )}
                </div>
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="previousAddress"
                  placeholder="Previous Address (if less than 3 years)"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousAddress}
                />
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="maritalStatus"
                  placeholder="Marital Status"
                  className="inputField"
                  style={{ width: "50%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.maritalStatus}
                />
                <input
                  type="text"
                  name="dependents"
                  placeholder="Dependents"
                  className="inputField"
                  style={{ width: "50%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dependents}
                />
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="homePhone"
                    placeholder="Home Phone*"
                    className={
                      formik.touched.homePhone && formik.errors.homePhone
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.homePhone}
                  />
                  {formik.touched.homePhone && formik.errors.homePhone && (
                    <span className="error">{formik.errors.homePhone}</span>
                  )}
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="officePhone"
                    placeholder="Office Phone"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.officePhone}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="cellPhone"
                    placeholder="Cell Phone*"
                    className={
                      formik.touched.cellPhone && formik.errors.cellPhone
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cellPhone}
                  />
                  {formik.touched.cellPhone && formik.errors.cellPhone ? (
                    <span className="error">{formik.errors.cellPhone}</span>
                  ) : null}
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="emailOne"
                    placeholder="Primary Email*"
                    className={
                      formik.touched.emailOne && formik.errors.emailOne
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emailOne}
                  />
                  {formik.touched.emailOne && formik.errors.emailOne ? (
                    <span className="error">{formik.errors.emailOne}</span>
                  ) : null}
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="emailTwo"
                    placeholder="Secondary Email"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emailTwo}
                  />
                </div>
              </div>
              <h4 style={{ marginTop: "20px" }}>
                Applicant Employment Information
              </h4>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="currentEmployer"
                    style={{ width: "100%" }}
                    placeholder="Current Employer"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.currentEmployer}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="durationEmployed"
                    style={{ width: "100%" }}
                    placeholder="Duration Employed"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.durationEmployed}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="Address"
                  placeholder="Address (complete)"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Address}
                />
              </div>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="number"
                    name="phone"
                    placeholder="Phone*"
                    style={{ width: "100%" }}
                    className={
                      formik.touched.phone && formik.errors.phone
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <span className="error">{formik.errors.phone}</span>
                  ) : null}
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="employmentEmail"
                    placeholder="Email*"
                    style={{ width: "100%" }}
                    className={
                      formik.touched.employmentEmail &&
                      formik.errors.employmentEmail
                        ? "inputField errorField"
                        : "inputField blueBorder"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.employmentEmail}
                  />
                  {formik.touched.employmentEmail &&
                  formik.errors.employmentEmail ? (
                    <span className="error">
                      {formik.errors.employmentEmail}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="position"
                    placeholder="Position"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.position}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="incomeType"
                    placeholder="Income Type"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.incomeType}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  {/* <input
                  type="number"
                  name="annualIncome"
                  placeholder="Gross Annual Income"
                  style={{ width: "100%" }}
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.annualIncome}
                /> */}
                  <CurrencyInput
                    prefix="$"
                    name="annualIncome"
                    id="annualIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Annual Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setAnnualIncome(currencyValue);
                    }}
                  />
                </div>
              </div>

              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="additionalJob"
                    placeholder="Additional job"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.additionalJob}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="addtionalIncomeType"
                    placeholder="Income Type"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.addtionalIncomeType}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <CurrencyInput
                    prefix="$"
                    name="grossIncome"
                    id="grossIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setGrossIncome(currencyValue);
                    }}
                  />
                </div>
              </div>

              {/* <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <CurrencyInput
                    prefix="$"
                    name="grossIncome"
                    id="grossIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setGrossIncome(currencyValue);
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <CurrencyInput
                    prefix="$"
                    name="grossIncome"
                    id="grossIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Total Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setGrossIncome(currencyValue);
                    }}
                  />
                </div>
              </div> */}
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="previousEmployer"
                    placeholder="Previous Employer (Complete only if current is less than 3 years)"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.previousEmployer}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="previousDurationEmployed"
                    placeholder="Duration Employed"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.previousDurationEmployed}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="previousPhone"
                    placeholder="Phone"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.previousPhone}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="previousEmail"
                    placeholder="Email"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.previousEmail}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="previousPosition"
                    placeholder="Position"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.previousPosition}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="previousIncomeType"
                    placeholder="Income Type"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.previousIncomeType}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  {/* <input
                  type="number"
                  name="previousAnnualIncome"
                  style={{ width: "100%" }}
                  placeholder="Gross Annual Income"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.previousAnnualIncome}
                /> */}
                  <CurrencyInput
                    prefix="$"
                    name="previousAnnualIncome"
                    id="previousAnnualIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Annual Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setPreviousAnnualIncome(currencyValue);
                    }}
                  />
                </div>
              </div>
              {applicant === "One" && (
                <div className="formBtnContainer">
                  <button
                    type="button"
                    onClick={backHandler}
                    className={step === 0 ? `disableBtn` : `enable`}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    key="next_button"
                    onClick={nextHandler}
                    className={
                      Object.keys(formik.errors).length !== 0 ||
                      formik.values.name === "" ||
                      formik.values.dob === "" ||
                      formik.values.homePhone === "" ||
                      formik.values.emailOne === "" ||
                      formik.values.phone === "" ||
                      formik.values.employmentEmail === "" ||
                      formik.values.durationAddress === "" ||
                      formik.values.cellPhone === ""
                        ? "resolveError"
                        : "noError"
                    }
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
            <div className="stepContainer stepTwo">
              <h4 style={{ marginTop: "20px" }}>
                Second Applicant Personal Information
              </h4>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondName"
                    id="name"
                    placeholder="Name(first, last)*"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondName}
                  />
                  {formik.touched.secondName && formik.errors.secondName ? (
                    <span className="error">{formik.errors.secondName}</span>
                  ) : null}
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondSin"
                    style={{ width: "100%" }}
                    placeholder="SIN"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondSin}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    placeholder="Date of Birth(DD/MM/YYYY)"
                    name="secondDob"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondDob}
                  />
                  {formik.touched.secondDob && formik.errors.secondDob && (
                    <span className="error">{formik.errors.secondDob}</span>
                  )}
                </div>
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="secondCurrentAddress"
                  placeholder="Current Address (complete)"
                  className="inputField"
                  style={{ width: "50%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondCurrentAddress}
                />
                <input
                  type="text"
                  name="secondDurationAddress"
                  placeholder="Duration at Address"
                  className="inputField"
                  style={{ width: "50%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondDurationAddress}
                />
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="secondPreviousAddress"
                  placeholder="Previous Address (if less than 3 years)"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondPreviousAddress}
                />
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="secondMaritalStatus"
                  placeholder="Marital Status"
                  className="inputField"
                  style={{ width: "50%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondMaritalStatus}
                />
                <input
                  type="text"
                  name="secondDependents"
                  placeholder="Dependents"
                  className="inputField"
                  style={{ width: "50%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondDependents}
                />
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondHomePhone"
                    placeholder="Home Phone"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondHomePhone}
                  />
                  {formik.touched.secondHomePhone &&
                    formik.errors.secondHomePhone && (
                      <span className="error">
                        {formik.errors.secondHomePhone}
                      </span>
                    )}
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondOfficePhone"
                    placeholder="Office Phone"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondOfficePhone}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondCellPhone"
                    placeholder="Cell Phone"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondCellPhone}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="secondEmailOne"
                    placeholder="Email"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondEmailOne}
                  />
                  {formik.touched.secondEmailOne &&
                    formik.errors.secondEmailOne && (
                      <span className="error">
                        {formik.errors.secondEmailOne}
                      </span>
                    )}
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="secondEmailTwo"
                    placeholder="Email"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondEmailTwo}
                  />
                </div>
              </div>
              <h4 style={{ marginTop: "20px" }}>
                Second Applicant Employment Information
              </h4>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="secondCurrentEmployer"
                    style={{ width: "100%" }}
                    placeholder="Current Employer"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondCurrentEmployer}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="secondDurationEmployed"
                    style={{ width: "100%" }}
                    placeholder="Duration Employed"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondDurationEmployed}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <input
                  type="text"
                  name="secondAddress"
                  placeholder="Address"
                  className="inputField"
                  style={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondAddress}
                />
              </div>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="secondPhone"
                    placeholder="Phone"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPhone}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="secondEmploymentEmail"
                    placeholder="Email"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondEmploymentEmail}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondPosition"
                    placeholder="Position"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPosition}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondIncomeType"
                    placeholder="Income Type"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondIncomeType}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  {/* <input
                    type="number"
                    name="secondAnnualIncome"
                    placeholder="Gross Annual Income"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondAnnualIncome}
                  /> */}
                  <CurrencyInput
                    prefix="$"
                    name="secondAnnualIncome"
                    id="secondAnnualIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Annual Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setSecondAnnualIncome(currencyValue);
                    }}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondAdditionalJob"
                    placeholder="Additional job"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondAdditionalJob}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondAddtionalIncomeType"
                    placeholder="Income Type"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondAddtionalIncomeType}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <CurrencyInput
                    prefix="$"
                    name="secondGrossIncome"
                    id="secondGrossIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setSecondGrossIncome(currencyValue);
                    }}
                  />
                </div>
              </div>
              {/* <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <CurrencyInput
                    prefix="$"
                    name="secondGrossIncome"
                    id="secondGrossIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setSecondGrossIncome(currencyValue);
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <CurrencyInput
                    prefix="$"
                    name="secondTotalIncome"
                    id="secondTotalIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Total Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setSecondTotalIncome(currencyValue);
                    }}
                  />
                </div>
              </div> */}
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="secondPreviousEmployer"
                    placeholder="Previous Employer (Complete only if current is less than 3 years)"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousEmployer}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="secondPreviousDurationEmployed"
                    placeholder="Duration Employed"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousDurationEmployed}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "50%" }}>
                  <input
                    type="text"
                    name="secondPreviousPhone"
                    placeholder="Phone"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousPhone}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <input
                    type="email"
                    name="secondPreviousEmail"
                    placeholder="Email"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousEmail}
                  />
                </div>
              </div>
              <div className="threeColBlock">
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondPreviousPosition"
                    placeholder="Position"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousPosition}
                    secondP
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  <input
                    type="text"
                    name="secondPreviousIncomeType"
                    placeholder="Income Type"
                    style={{ width: "100%" }}
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousIncomeType}
                  />
                </div>
                <div style={{ width: "33.33%" }}>
                  {/* <input
                    type="number"
                    name="secondPreviousAnnualIncome"
                    style={{ width: "100%" }}
                    placeholder="Gross Annual Income"
                    className="inputField"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondPreviousAnnualIncome}
                  /> */}
                  <CurrencyInput
                    prefix="$"
                    name="secondPreviousAnnualIncome"
                    id="secondPreviousAnnualIncome"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    placeholder="Gross Annual Income"
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    className="inputField"
                    onChange={(e) => {
                      const value = e.target.value;
                      const parsedValue = value.replace(/[^\d.]/gi, "");
                      const currencyInt = parseInt(parsedValue);
                      const currencyValue = currencyInt.toLocaleString();
                      setSecondPreviousAnnualIncome(currencyValue);
                    }}
                  />
                </div>
              </div>
              {applicant === "Two" && (
                <div className="formBtnContainer">
                  <button
                    type="button"
                    onClick={backHandler}
                    className={step === 0 ? `disableBtn` : `enable`}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    key="next_button"
                    onClick={nextHandler}
                    className={
                      Object.keys(formik.errors).length !== 0 ||
                      formik.values.name === "" ||
                      formik.values.dob === "" ||
                      formik.values.homePhone === "" ||
                      formik.values.emailOne === "" ||
                      formik.values.phone === "" ||
                      formik.values.employmentEmail === "" ||
                      formik.values.durationAddress === "" ||
                      formik.values.cellPhone === ""
                        ? "resolveError"
                        : "noError"
                    }
                  >
                    Next
                  </button>
                </div>
              )}
              {/* {applicant === "Two" && (
                <div className="formBtnContainer">
                  <button
                    type="button"
                    onClick={backHandler}
                    className={step === 0 ? `disableBtn` : `enable`}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    key="next_button"
                    onClick={nextHandler}
                    className={
                      Object.keys(formik.errors).length !== 0 ||
                      formik.values.name === "" ||
                      formik.values.dob === "" ||
                      formik.values.homePhone === "" ||
                      formik.values.emailOne === "" ||
                      formik.values.phone === "" ||
                      formik.values.employmentEmail === "" ||
                      formik.values.durationAddress === "" ||
                      formik.values.cellPhone === ""
                        ? "resolveError"
                        : "noError"
                    }
                  >
                    Next
                  </button>
                </div>
              )} */}
            </div>
          </>
        ) : null}
        {step === 2 ? (
          <div className="financialInformation">
            <div className="assetsContainer">
              <div className="assetsHead">
                <h4>Assets</h4>
              </div>
              <div className="assetsHead">
                <h4>Values</h4>
              </div>
              <div className="assetOne assets">
                <p>Subject Property</p>
              </div>
              <div className="assetOneValue assets">
                <input
                  type="number"
                  name="subjectProperty"
                  placeholder="Subject Property"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subjectProperty}
                />
              </div>
              <div className="assetTwo assets">
                <p>Total Other Real Estate</p>
              </div>
              <div className="assetTwoValue assets">
                <input
                  type="number"
                  name="totalOtherRealEstate"
                  placeholder="Total Other Real Estate"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalOtherRealEstate}
                />
              </div>
              <div className="assetThree assets">
                <p>Cash in Bank</p>
              </div>
              <div className="assetThreeValue assets">
                {/* <input
                  type="number"
                  name="cashInBank"
                  placeholder="Cash in Bank"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cashInBank}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="cashInBank"
                  id="cashInBank"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Cash in Bank"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setCashInBank(currencyValue);
                  }}
                />
              </div>
              <div className="assetFour assets">
                <p>Business</p>
              </div>
              <div className="assetFourValue assets">
                <input
                  type="number"
                  name="business"
                  placeholder="Business"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.business}
                />
              </div>
              <div className="assetFour assets">
                <p>Stock, Bonds, Investments, Etc.</p>
              </div>
              <div className="assetFourValue assets">
                {/* <input
                  type="number"
                  name="stocksBondsInvestment"
                  placeholder="Stock, Bonds, Investments, Etc."
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stocksBondsInvestment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="stocksBondsInvestment"
                  id="stocksBondsInvestment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Stock, Bonds, Investments, Etc."
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setStocksBondsInvestment(currencyValue);
                  }}
                />
              </div>
              <div className="assetFour assets">
                <p>Household Effects</p>
              </div>
              <div className="assetFourValue assets">
                {/* <input
                  type="number"
                  name="householdEffects"
                  placeholder="Household Effects"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.householdEffects}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="householdEffects"
                  id="householdEffects"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Household Effects"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setHouseholdEffects(currencyValue);
                  }}
                />
              </div>
              <div className="assetFour assets">
                <p>Automobiles, Trailers, Etc.</p>
              </div>
              <div className="assetFourValue assets">
                {/* <input
                  type="number"
                  name="automobilesTrailers"
                  placeholder="Automobiles, Trailers, Etc."
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.automobilesTrailers}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="automobilesTrailers"
                  id="automobilesTrailers"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Automobiles, Trailers, Etc."
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setAutomobilesTrailers(currencyValue);
                  }}
                />
              </div>
              <div className="assetFour assets">
                <p>Others</p>
              </div>
              <div className="assetFourValue assets">
                {/* <input
                  type="number"
                  name="other"
                  placeholder="Others"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.other}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="other"
                  id="other"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Others"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setOther(currencyValue);
                  }}
                />
              </div>
              <div className="assetFour assets">
                <p>Total Assets</p>
              </div>
              <div className="assetFourValue assets">
                {/* <input
                  type="number"
                  name="totalAssets"
                  placeholder="Total Assets"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalAssets}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="totalAssets"
                  id="totalAssets"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Total Assets"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setTotalAssets(currencyValue);
                  }}
                />
              </div>
              <div className="assetFour assets">
                <p>Total Net Worth</p>
              </div>
              <div className="assetFourValue assets">
                {/* <input
                  type="number"
                  name="totalNetWorth"
                  placeholder="Total Net Worth"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalNetWorth}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="totalNetWorth"
                  id="totalNetWorth"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Total Net Worth"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setTotalNetWorth(currencyValue);
                  }}
                />
              </div>
            </div>
            <div className="liabilitiesContainer">
              <div className="liabilitiesHead">
                <h4>Liabilities</h4>
              </div>
              <div className="liabilitiesHead">
                <h4>Balance</h4>
              </div>
              <div className="liabilitiesHead">
                <h4>Payment</h4>
              </div>
              <div className="liabilityOne liability">
                <p>Mortgage/Loan</p>
              </div>
              <div className="liabilityOneBalance assets">
                {/* <input
                  type="number"
                  name="mortgageBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mortgageBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="mortgageBalance"
                  id="mortgageBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setMortgageBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityOnePayment assets">
                {/* <input
                  type="number"
                  name="mortgagePayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mortgagePayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="mortgagePayment"
                  id="mortgagePayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setMortgagePayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Total Other Mortgage(s)</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="otherMortgageBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otherMortgageBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="otherMortgageBalance"
                  id="otherMortgageBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setOtherMortgageBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="otherMortgagePayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otherMortgagePayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="otherMortgagePayment"
                  id="otherMortgagePayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setOtherMortgagePayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Personal Loans</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="personalLoanBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personalLoanBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="personalLoanBalance"
                  id="personalLoanBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setPersonalLoanBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="personalLoanPayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personalLoanPayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="personalLoanPayment"
                  id="personalLoanPayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setPersonalLoanPayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Credit Cards/Lines</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="creditCardsBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.creditCardsBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="creditCardsBalance"
                  id="creditCardsBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setCreditCardsBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="creditCardsPayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.creditCardsPayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="creditCardsPayment"
                  id="creditCardsPayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setCreditCardsPayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Student Loans</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="studentLoanBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.studentLoanBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="studentLoanBalance"
                  id="studentLoanBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setStudentLoanBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="studentLoanPayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.studentLoanPayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="studentLoanPayment"
                  id="studentLoanPayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setStudentLoanPayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Auto Loans/Leases</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="autoLoanBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.autoLoanBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="autoLoanBalance"
                  id="autoLoanBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setAutoLoanBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="autoLoanPayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.autoLoanPayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="autoLoanPayment"
                  id="autoLoanPayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setAutoLoanPayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Other</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="otherLoanBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otherLoanBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="otherLoanBalance"
                  id="otherLoanBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setOtherLoanBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="otherLoanPayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otherLoanPayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="otherLoanPayment"
                  id="otherLoanPayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setOtherLoanPayment(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwo liability">
                <p>Total Liabilities</p>
              </div>
              <div className="liabilityTwoBalance assets">
                {/* <input
                  type="number"
                  name="totalLiabilitiesBalance"
                  placeholder="Balance"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalLiabilitiesBalance}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="totalLiabilitiesBalance"
                  id="totalLiabilitiesBalance"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Balance"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setTotalLiabilitiesBalance(currencyValue);
                  }}
                />
              </div>
              <div className="liabilityTwoPayment assets">
                {/* <input
                  type="number"
                  name="totalLiabilitiesPayment"
                  placeholder="Payment"
                  className="inputField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalLiabilitiesPayment}
                /> */}
                <CurrencyInput
                  prefix="$"
                  name="totalLiabilitiesPayment"
                  id="totalLiabilitiesPayment"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  placeholder="Payment"
                  allowDecimals
                  decimalsLimit="2"
                  disableAbbreviations
                  className="inputField"
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    const currencyInt = parseInt(parsedValue);
                    const currencyValue = currencyInt.toLocaleString();
                    setTotalLiabilitiesPayment(currencyValue);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}

        {step === 3 ? (
          props.endScreen ? (
            <h4 style={{ textAlign: "center" }}>
              Thank you for your response. We wil get back to you.
            </h4>
          ) : (
            <>
              <div className="formContainer">
                <div className="formCol1 formCol formFlex">
                  <h2>
                    SUBJECT PROPERTY TO BE MORTGAGED, RE-MORTGAGED OR EQUITY
                    TAKE OUT
                  </h2>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyAddress"
                      placeholder="Address"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyAddress}
                    />
                  </div>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyLegal"
                      placeholder="Legal"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyLegal}
                    />
                  </div>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyPosition"
                      placeholder="Position"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyPosition}
                    />
                  </div>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyAppraisalCompany"
                      placeholder="Appraisal Company"
                      className="inputField"
                      style={{ width: "50%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyAppraisalCompany}
                    />
                    <input
                      type="text"
                      name="subjectPropertyAppraiserName"
                      placeholder="Appraiser Name"
                      className="inputField"
                      style={{ width: "50%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyAppraiserName}
                    />
                  </div>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyAppraisalDate"
                      placeholder="Appraisal Date"
                      className="inputField"
                      style={{ width: "50%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyAppraisalDate}
                    />
                    {/* <input
                    type="text"
                    name="subjectPropertyAppraisedValue"
                    placeholder="Appraised Value"
                    className="inputField"
                    style={{ width: "50%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subjectPropertyAppraisedValue}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="subjectPropertyAppraisedValue"
                      id="subjectPropertyAppraisedValue"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      placeholder="Appraised Value"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setSubjectPropertyAppraisedValue(currencyValue);
                      }}
                    />
                  </div>
                  <div className="threeColBlock">
                    {/* <input
                    type="text"
                    name="subjectPropertyAnnualPropertyTax"
                    placeholder="Annual Property Tax"
                    className="inputField"
                    style={{ width: "33.33%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subjectPropertyAnnualPropertyTax}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="subjectPropertyAnnualPropertyTax"
                      id="subjectPropertyAnnualPropertyTax"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      placeholder="Annual Property Tax"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setSubjectPropertyAnnualPropertyTax(currencyValue);
                      }}
                    />
                    {/* <input
                    type="text"
                    name="subjectPropertyMonthlyMaintFee"
                    placeholder="Monthly Maint. Fee"
                    className="inputField"
                    style={{ width: "33.33%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subjectPropertyMonthlyMaintFee}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="subjectPropertyMonthlyMaintFee"
                      id="subjectPropertyMonthlyMaintFee"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      placeholder="Monthly Maint. Fee"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setSubjectPropertyMonthlyMaintFee(currencyValue);
                      }}
                    />
                    <input
                      type="text"
                      name="subjectPropertyArrears"
                      placeholder="Arrears (If Any)"
                      className="inputField"
                      style={{ width: "33.33%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyArrears}
                    />
                  </div>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyOccupancy"
                      placeholder="Occupancy"
                      className="inputField"
                      style={{ width: "50%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyOccupancy}
                    />
                    <input
                      type="text"
                      name="subjectPropertyStyle"
                      placeholder="Style"
                      className="inputField"
                      style={{ width: "50%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyStyle}
                    />
                  </div>
                  <div className="threeColBlock">
                    <input
                      type="text"
                      name="subjectPropertyZoning"
                      placeholder="Zoning"
                      className="inputField"
                      style={{ width: "33.33%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyZoning}
                    />
                    <input
                      type="text"
                      name="subjectPropertyNumberOfUnits"
                      placeholder="Number of Units"
                      className="inputField"
                      style={{ width: "33.33%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subjectPropertyNumberOfUnits}
                    />
                    {/* <input
                    type="text"
                    name="subjectPropertyRentalIncome"
                    placeholder="Rental Income"
                    className="inputField"
                    style={{ width: "33.33%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subjectPropertyRentalIncome}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="subjectPropertyRentalIncome"
                      id="subjectPropertyRentalIncome"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      placeholder="Rental Income"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setSubjectPropertyRentalIncome(currencyValue);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="formContainerSecond">
                <h2>PRIOR ENCUMBRANCES (EXISTING OR ANTICIPATED)</h2>
                <div className="secondForm">
                  <div className="secondFormCol"></div>
                  <div className="secondFormCol">
                    <h4>1st Mortgage</h4>
                  </div>
                  <div className="secondFormCol">
                    <h4>2nd Mortgage</h4>
                  </div>
                  <div className="secondFormCol">
                    <h4>3rd Mortgage</h4>
                  </div>
                  <div className="secondFormCol">
                    <h4>Mortgage Holder</h4>
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="mortgageHolderFirstMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mortgageHolderFirstMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="mortgageHolderSecondMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mortgageHolderSecondMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="mortgageHolderThirdMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mortgageHolderThirdMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <h4>Mortgage Balance</h4>
                  </div>
                  <div className="secondFormCol">
                    {/* <input
                    type="text"
                    name="mortgageBalanceFirstMortgage"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mortgageBalanceFirstMortgage}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="mortgageBalanceFirstMortgage"
                      id="mortgageBalanceFirstMortgage"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setMortgageBalanceFirstMortgage(currencyValue);
                      }}
                    />
                  </div>
                  <div className="secondFormCol">
                    {/* <input
                    type="text"
                    name="mortgageBalanceSecondMortgage"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mortgageBalanceSecondMortgage}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="mortgageBalanceSecondMortgage"
                      id="mortgageBalanceSecondMortgage"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setMortgageBalanceSecondMortgage(currencyValue);
                      }}
                    />
                  </div>
                  <div className="secondFormCol">
                    {/* <input
                    type="text"
                    name="mortgageBalanceThirdMortgage"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mortgageBalanceThirdMortgage}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="mortgageBalanceThirdMortgage"
                      id="mortgageBalanceThirdMortgage"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setMortgageBalanceThirdMortgage(currencyValue);
                      }}
                    />
                  </div>
                  <div className="secondFormCol">
                    <h4>Monthly Payments</h4>
                  </div>
                  <div className="secondFormCol">
                    {/* <input
                    type="text"
                    name="monthlyPaymentsFirstMortgage"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.monthlyPaymentsFirstMortgage}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="monthlyPaymentsFirstMortgage"
                      id="monthlyPaymentsFirstMortgage"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setMonthlyPaymentsFirstMortgage(currencyValue);
                      }}
                    />
                  </div>
                  <div className="secondFormCol">
                    {/* <input
                    type="text"
                    name="monthlyPaymentsSecondMortgage"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.monthlyPaymentsSecondMortgage}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="monthlyPaymentsSecondMortgage"
                      id="monthlyPaymentsSecondMortgage"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setMonthlyPaymentsSecondMortgage(currencyValue);
                      }}
                    />
                  </div>
                  <div className="secondFormCol">
                    {/* <input
                    type="text"
                    name="monthlyPaymentsThirdMortgage"
                    className="inputField"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.monthlyPaymentsThirdMortgage}
                  /> */}
                    <CurrencyInput
                      prefix="$"
                      name="monthlyPaymentsThirdMortgage"
                      id="monthlyPaymentsThirdMortgage"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                      className="inputField"
                      onChange={(e) => {
                        const value = e.target.value;
                        const parsedValue = value.replace(/[^\d.]/gi, "");
                        const currencyInt = parseInt(parsedValue);
                        const currencyValue = currencyInt.toLocaleString();
                        setMonthlyPaymentsThirdMortgage(currencyValue);
                      }}
                    />
                  </div>
                  <div className="secondFormCol">
                    <h4>Interest Rate</h4>
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="interestRateFirstMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.interestRateFirstMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="interestRateSecondMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.interestRateSecondMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="interestRateThirdMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.interestRateThirdMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <h4>Maturity Date</h4>
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="maturityDateFirstMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.maturityDateFirstMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="maturityDateSecondMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.maturityDateSecondMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="maturityDateThirdMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.maturityDateThirdMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <h4>Arrears (If Any)</h4>
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="arrearFirstMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.arrearFirstMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="arrearSecondMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.arrearSecondMortgage}
                    />
                  </div>
                  <div className="secondFormCol">
                    <input
                      type="text"
                      name="arrearThirdMortgage"
                      className="inputField"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.arrearThirdMortgage}
                    />
                  </div>
                </div>
              </div>
              <div className="consentContainer">
                <input type="checkbox" name="consent" />
                <label>
                  I/We the undersigned, declare the information provided in the
                  mortgage applicaiton is true and a complete representation.
                  I/We authorize MortgagePro Ltd. or their designate to obtain a
                  credit report. I/We authorize MortgagePro Ltd. to obtain
                  further information to confirm the details that have been
                  provided and the creditworthiness of the application. I/We
                  expressly consent to the submission of my applicaiton and the
                  disclosure of information to third parties, such as lenders
                  and insurers, for the purpose of arranging and/or renewing
                  mortgage(s). MortgagePro Ltd. may collect and use personal
                  information from you and about you to meet all legal and
                  regulatory requirements.
                </label>
              </div>
            </>
          )
        ) : null}
        {step === 2 || step === 3 ? (
          props.endScreen ? null : (
            <div className="formBtnContainer">
              <button
                type="button"
                onClick={backHandler}
                className={step === 0 ? `disableBtn` : `enable`}
              >
                Back
              </button>
              {step === 3 ? (
                <button
                  type="submit"
                  className={
                    Object.keys(formik.errors).length !== 0
                      ? `resolveError`
                      : "noError"
                  }
                >
                  {props.spinnerCheck ? <CircularProgress /> : "Submit"}
                </button>
              ) : (
                <button
                  type="button"
                  key="next_button"
                  onClick={nextHandler}
                  className={
                    Object.keys(formik.errors).length !== 0
                      ? `resolveError`
                      : "noError"
                  }
                >
                  Next
                </button>
              )}
            </div>
          )
        ) : null}
      </form>
    </div>
  );
};

export default LatestForm;
